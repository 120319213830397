export default {
  data() {
    return {
      list: [],
      currentPage: 1,
      pageSize: 10,
      isEmyty: false,
      isEnd: false,
      loading: false,
      error: false,
      count: 0
    }
  },
  methods: {
    initData() {
      this.currentPage = 1
      this.list = []
      this.isEmyty = false
      this.isEnd = false
      this.loading = false
      this.error = false
      this.count = 0
    },
    setData({ list, currentPage, countPage, count }) {
      this.list = this.list.concat(list)
      if (countPage <= currentPage) {
        this.isEnd = true
      }
      if (!this.list.length) {
        this.isEmyty = true
      }
      this.count = count
      this.loading = false
      this.currentPage++
    }
  }
}
