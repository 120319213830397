<template>
  <div class="order-container">
    <div class="notice">
      <p>
        <span style="color: #f00;"
          >充值成功后，请下线重上，然后在商城中手动收账，元宝即可到账！</span
        ><span>若充值长时间未到账，请联系客服，客服微信：{{ wechatId }}</span>
      </p>
    </div>
    <van-field placeholder="请输入用户名" v-model="account" clearable>
      <template #button>
        <van-button size="small" type="primary" @click="selectOrders"
          ><van-icon name="search"
        /></van-button>
      </template>
    </van-field>
    <list
      emptyNotice="未查询到订单"
      :load="onLoad"
      :data="{ account: account }"
      ref="order-list"
      class="order-list"
    >
      <template v-slot="scope">
        <div class="order-item">
          <div class="order-header">
            <span class="order-sn">{{ scope.item.orderSn }}</span>
          </div>
          <div class="order-body">
            <p>
              充值时间：
              <span>{{
                parseTime(scope.item.createTime, '{y}-{m}-{d} {h}:{i}:{s}')
              }}</span>
            </p>
            <p>
              账户信息：
              {{
                `${scope.item.product.servername}-${scope.item.product.charname}`
              }}
            </p>
            <p>充值内容： {{ scope.item.product.productName }}</p>
          </div>
          <div class="order-footer">
            <span v-if="scope.item.payStatus === 0">未支付</span>
            <span v-if="scope.item.payStatus === 1">已支付</span>
            <span v-if="scope.item.payStatus === 2">支付失败</span>
            <span>，</span>
            <span v-if="scope.item.deliveryStatus === 0">未发货</span>
            <span v-if="scope.item.deliveryStatus === 1">已发货</span>
            <span v-if="scope.item.deliveryStatus === 2">发货失败</span>
          </div>
        </div>
      </template>
    </list>
  </div>
</template>
<script>
import { Field, Button } from 'vant'
import List from '@/components/List'
import { mapActions, mapState } from 'vuex'
import { parseTime } from '@/utils'

export default {
  name: 'OrderList',
  components: {
    'van-field': Field,
    'van-button': Button,
    List,
  },
  data() {
    return {
      account: '',
    }
  },
  computed: {
    ...mapState({ wechatId: (state) => state.setting.setting.wechatId }),
  },
  mounted() {
    let account = window.localStorage.getItem('account')
    if (account) {
      this.account = account
    }
  },
  methods: {
    ...mapActions('order', ['getOrderList']),
    parseTime,
    onLoad(data) {
      return this.getOrderList(data)
    },
    selectOrders() {
      this.$refs['order-list'].initData()
      this.$refs['order-list'].checkScroll()
    },
  },
}
</script>
<style lang="less" scoped>
.order-container {
  padding: 0 20px;

  .notice {
    margin: 20px 0;
  }
}

.order-list {
  margin-top: 10px;

  .order-item {
    padding: 10px;
    background-color: #fff;
    margin-bottom: 10px;

    .order-header {
      padding: 8px 0;
      border-bottom: 1px solid #e5e5e5;

      .order-sn {
      }
    }

    .order-body {
      padding: 8px 0;
      border-bottom: 1px solid #e5e5e5;
    }

    .order-footer {
      padding: 8px 0;
    }
  }
}
</style>
