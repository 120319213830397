<template>
  <div>
    <van-list
      :error.sync="error"
      error-text="请求失败，点击重新加载"
      :finished-text="isEmyty ? '' : '没有更多了'"
      @load="onLoad"
      :finished="isEnd"
      v-model="loading"
      ref="list"
    >
      <div v-for="item in list" :key="item._id">
        <slot :item="item"></slot>
      </div>

      <template #loading>
        <van-loading class="loading" type="circular" v-if="loading" size="20px"
          >加载中...</van-loading
        >
      </template>
    </van-list>
    <van-empty v-if="isEmyty" :description="emptyNotice" />
  </div>
</template>

<script>
import { List, Empty, Loading } from 'vant'
import LoadMore from '@/components/mixins/LoadMore'

export default {
  name: 'List',
  mixins: [LoadMore],
  components: {
    'van-list': List,
    'van-empty': Empty,
    'van-loading': Loading,
  },
  data() {
    return {}
  },
  props: {
    emptyNotice: {
      type: String,
      default: '暂时没有记录',
    },
    data: {
      type: Object,
      default() {
        return {}
      },
    },
    load: {
      type: Function,
      default() {
        return () => {}
      },
    },
  },
  mounted() {},
  methods: {
    async onLoad() {
      try {
        let data = Object.assign(
          { currentPage: this.currentPage, pageSize: this.pageSize },
          this.data
        )
        let res = await this.load.call(null, data)
        this.setData(res.data)
      } catch (err) {
        this.loading = false
        this.error = true
        console.log(err)
      }
    },
    checkScroll() {
      this.$refs['list'] && this.$refs['list'].check()
    },
  },
}
</script>
